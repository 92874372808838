require('../shared-ui/src/styles/global.css');

const React = require('react')
const { PageLayout } = require('../shared-ui/src/components/PageLayout');
const { ThemeProvider } = require('@material-ui/core/styles');
const { theme } = require('./src/theme/theme');

exports.onRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}

exports.wrapPageElement = ({ element, props }) => {
  return <ThemeProvider theme={theme}><PageLayout {...props}>{element}</PageLayout></ThemeProvider>
}