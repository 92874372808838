import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'; 
import { Paper, Container, Typography, Toolbar, AppBar, IconButton, makeStyles, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { GameProvider } from '../../store';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mainContainer: {
    marginTop: 20
  }
}));

export default function PageLayout(props) {
  const {
    children,
  } = props;
  
  const classes = useStyles();

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      </Helmet>
      <div className={classes.root}>
        <AppBar position="static">
          <Container maxWidth="md">
            <Toolbar>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="h1" className={classes.title}>
                Dog or Wizard
              </Typography>
              <Button color="inherit">Submit your dog</Button>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
      <Container maxWidth="md" className={classes.mainContainer}>
        <Paper elevation="2">
          <main>
            <GameProvider>
              {children}
            </GameProvider>
          </main>
        </Paper>
      </Container>
    </Fragment>
  )
}