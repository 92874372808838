import React, {createContext, useReducer} from 'react';

const initialState = {};
const store = createContext(initialState);
const { Provider } = store;

// Actions
export const ANSWER_ACTION = 'answer-question';


export const initialGameState = {
  answers: [1,2,3,4,5,6,7,8,9,10].map(id => 'pending'),
  correct: 0,
};

// Provider
const GameProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case ANSWER_ACTION:
        const {gameId, questionId, correct} = action.payload;
        const currentGameState = state[gameId] || initialGameState;  

        const newState = {
          ...state,
          [gameId]: {
            ...currentGameState
          }
        };

        if (correct) {
          ++newState[gameId].correct
        }

        newState[gameId].answers[questionId] = correct ? 'correct' : 'wrong';

        return newState;
      default:
        throw new Error();
    };
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, GameProvider }